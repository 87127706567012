import React, { useContext } from 'react';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import {
	ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { CompanyHomeBuilderUpsellPage } from '@confluence/change-edition/entry-points/CompanyHomeBuilderUpsellPage';
import { useCompanyHubPremiumGate } from '@confluence/company-hub/entry-points/useCompanyHubPremiumGate';
import { getValue } from '@confluence/lifted-promise';
import { LoadablePaint } from '@confluence/loadable';
import type { RouteMatch } from '@confluence/route';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { AccessStatus, getSessionData } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';

const SpacePermissionsSettingsRouteComponent = LoadablePaint({
	loader: async () =>
		import(
			/* webpackChunkName: "loadable-SpacePermissionSettingsRouteComponent" */ './SpacePermissionsSettingsRouteComponent'
		),
});

const DEFAULT_TAB = 'users';

export const SpacePermissionsSettingsRoute = ({
	params: { spaceKey, tab = DEFAULT_TAB },
}: RouteMatch) => {
	const isCompanyHub = isCompanyHubSpaceKey(spaceKey);
	const shouldShowPremiumUpsell = useCompanyHubPremiumGate({
		skip: !isCompanyHub,
	});
	const { isSiteAdmin } = useContext(SPAViewContext);

	return isCompanyHub && isSiteAdmin && shouldShowPremiumUpsell ? (
		<CompanyHomeBuilderUpsellPage featureName="companyHubPermissions" />
	) : (
		<SpacePermissionsSettingsRouteComponent spaceKey={spaceKey} tab={tab} />
	);
};

SpacePermissionsSettingsRoute.NAVIGATION_PARAMS = ({
	name,
	params: { spaceKey, tab = DEFAULT_TAB },
}) => ({
	Screen: {
		screenEvent: {
			name:
				tab === 'public-links'
					? 'spacePermissionsPublicLinksScreen'
					: `${tab}SpacePermissionsScreen`,
			id: spaceKey,
		},
		pageState: { spaceKey, routeName: name },
	},
	MainLayout: {
		navView: getSideNavigationView(spaceKey),
		spaceKey,
	},
});

function getSideNavigationView(spaceKey: string | null | undefined) {
	if (
		!process.env.REACT_SSR &&
		isCompanyHubSpaceKey(spaceKey) &&
		FeatureGates.checkGate('confluence_frontend_company_hub_ga_customize_nav')
	) {
		// https://hello.atlassian.net/wiki/spaces/~701217e532d6bdc7846948a0f4e8afe31a122/pages/3993181421/Company+Hub+-+Beta+Tag+Removal
		// documents the product and design folks of Company Hub want Hub permissions to be among the site admin screens of the product,
		// specifically next to permisssions-related screens such as Global permissions, Space permissions, Analytics permissions, etc in
		// the Security section.
		const sessionData = getValue(getSessionData());
		// But for non-site admins the site admin side nav is pretty much empty and, more importantly, there are no other
		// permissions-related screens there. Then Hub permissions in the site admin side nav can only make sense to site admins.
		if (sessionData?.accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS) {
			return ADMIN_HOME_ID;
		}
	}

	return CONTAINER_HOME_ID;
}
